import {
    listThirdpartyApps as listThirdpartyAppsApi,
    listEnterpriseThirdpartyApps as listEnterpriseThirdpartyAppsApi,
    getAccessToken as getAccessTokenApi,
    deleteThirdpartyapps as deleteThirdpartyappsApi
} from 'helpers';
import { SagaIterator } from 'redux-saga';
import { all, fork, call, takeEvery, put } from 'redux-saga/effects';
import { ThirdpartyApp, thirdpartyAppApiResponseError, thirdpartyAppApiResponseSuccess } from './actions';
import { ThirdpartyAppsActionTypes } from './constants';

type ThirdpartyAppPayload = {
    payload: {
        enterpriseId?: number;
        id?: number
        count?: number
        page?: number
        pageSize?: number
        q?: string;
        data: ThirdpartyApp | {}
    };
    type: string;
};

function* listThirdpartyApps({ payload: { q, page = 0, pageSize }, type }: ThirdpartyAppPayload): SagaIterator {
    try {
        const response = yield call(listThirdpartyAppsApi, q, page, pageSize,);
        const { apps, page: {
            totalCounts
        } } = response.data;
        yield put(thirdpartyAppApiResponseSuccess(ThirdpartyAppsActionTypes.LIST_THIRDPARTYAPPS, apps, {
            pageCount: totalCounts,
        }))
    } catch (error: any) {
        yield put(thirdpartyAppApiResponseError(ThirdpartyAppsActionTypes.LIST_THIRDPARTYAPPS, error));
    }
}

function* listEnterpriseThirdpartyApps({ payload: { enterpriseId, q, page = 0, pageSize, }, type }: ThirdpartyAppPayload): SagaIterator {
    try {
        const response = yield call(listEnterpriseThirdpartyAppsApi, enterpriseId!, q, page, pageSize,);
        const { apps, page: {
            totalCounts
        } } = response.data;
        yield put(thirdpartyAppApiResponseSuccess(ThirdpartyAppsActionTypes.LIST_THIRDPARTYAPPS, apps, {
            pageCount: totalCounts,
        }))
    } catch (error: any) {
        yield put(thirdpartyAppApiResponseError(ThirdpartyAppsActionTypes.LIST_THIRDPARTYAPPS, error));
    }
}

function* getAccessToken({ payload: { id }, type }: ThirdpartyAppPayload): SagaIterator {
    try {
        const response = yield call(getAccessTokenApi, id!);
        const { app } = response.data;
        yield put(thirdpartyAppApiResponseSuccess(ThirdpartyAppsActionTypes.PUT_THIRDPARTYAPP, app))
    } catch (error: any) {
        yield put(thirdpartyAppApiResponseError(ThirdpartyAppsActionTypes.PUT_THIRDPARTYAPP, error));
    }
}

function* deleteThirdpartyApp({ payload: { id }, type }: ThirdpartyAppPayload): SagaIterator {
    try {
        const response = yield call(deleteThirdpartyappsApi, id!);
        yield put(thirdpartyAppApiResponseSuccess(ThirdpartyAppsActionTypes.DELETE_THIRDPARTYAPP, { id }))
    } catch (error: any) {
        yield put(thirdpartyAppApiResponseError(ThirdpartyAppsActionTypes.DELETE_THIRDPARTYAPP, error));
    }
}

function* watchListThirdpartyApps() {
    yield takeEvery(ThirdpartyAppsActionTypes.LIST_THIRDPARTYAPPS, listThirdpartyApps)
}

function* watchListEnterpriseThirdpartyApps() {
    yield takeEvery(ThirdpartyAppsActionTypes.LIST_ENTERPRISE_THIRDPARTYAPPS, listEnterpriseThirdpartyApps)
}

function* watchGetAccessToken() {
    yield takeEvery(ThirdpartyAppsActionTypes.GET_ACCESSTOKEN, getAccessToken)
}

function* watchDeleteThirdpartyApp() {
    yield takeEvery(ThirdpartyAppsActionTypes.DELETE_THIRDPARTYAPP, deleteThirdpartyApp)
}

function* thirdpartAppSaga() {
    yield all([
        fork(watchListThirdpartyApps),
        fork(watchListEnterpriseThirdpartyApps),
        fork(watchGetAccessToken),
        fork(watchDeleteThirdpartyApp),
    ])
}

export default thirdpartAppSaga;