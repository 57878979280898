import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import {
  listProducts as listProductsApi,
  syncProduct as syncProductApi,
  deleteProduct as deleteProductApi
} from 'helpers';
import { productApiResponseSuccess, productApiResponseError, ProductType } from './actions';
import { ProductActionTypes } from './constants';

type ProductPayload = {
  payload: {
    id?: string | number,
    enterpriseId?: number,
    data: ProductType[] | ProductType | {},
    page?: number,
    pageSize?: number,
    provider: string
  }
  type: string
}

function* listProducts({ payload: { enterpriseId, provider, page = 1,
  pageSize }, type }: ProductPayload): SagaIterator {
  try {
    const response = yield call(listProductsApi, enterpriseId, provider, page, pageSize);
    const { products, page: {
      totalCounts
    } } = response.data;
    yield put(productApiResponseSuccess(ProductActionTypes.LIST_PRODUCTS, products, {
      pageCount: totalCounts,
    }))
  } catch (error: any) {
    yield put(productApiResponseError(ProductActionTypes.LIST_PRODUCTS, error));
  }
}

function* syncProduct({ payload: { id }, type }: ProductPayload): SagaIterator {
  try {
    const response = yield call(syncProductApi, id!);
    const { product } = response.data;
    yield put(productApiResponseSuccess(ProductActionTypes.SYNC_PRODUCT, product))
  } catch (error: any) {
    yield put(productApiResponseError(ProductActionTypes.SYNC_PRODUCT, error));
  }
}

function* deleteProduct({ payload: { id }, type }: ProductPayload): SagaIterator {
  try {
    const response = yield call(deleteProductApi, id!);
    yield put(productApiResponseSuccess(ProductActionTypes.DELETE_PRODUCT, { id }))
  } catch (error: any) {
    yield put(productApiResponseError(ProductActionTypes.DELETE_PRODUCT, error));
  }
}

function* watchListProducts() {
  yield takeEvery(ProductActionTypes.LIST_PRODUCTS, listProducts);
}

function* watchSyncProduct() {
  yield takeEvery(ProductActionTypes.SYNC_PRODUCT, syncProduct);
}

function* watchDeleteProduct() {
  yield takeEvery(ProductActionTypes.DELETE_PRODUCT, deleteProduct);
}


function* productSaga() {
  yield all([fork(watchListProducts), fork(watchSyncProduct), fork(watchDeleteProduct)])
}

export default productSaga;