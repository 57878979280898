import { Page } from "redux/common";
import { ExtOrdersActionTypes } from "./constants";

export type ExtOrdersActionType = {
  type:
  | ExtOrdersActionTypes.API_RESPONSE_SUCCESS
  | ExtOrdersActionTypes.API_RESPONSE_ERROR
  | ExtOrdersActionTypes.LIST_EXTORDERS
  | ExtOrdersActionTypes.LIST_ENTERPRISEEXTORDERS
  | ExtOrdersActionTypes.RESET
  payload: {} | string;
}

export interface ExtOrdersType {
  id: number
  provider: string
  serviceId: number
  enterpriseId: number
  orderId: string
  clickId: string
  phone: string
  quantity: number
  price: number
  total: number
  orderStatus: string
  registerStatus: string
  registerAt: any
  registerMessage: string
  orderMessage: string
  attributes: Attributes
  createdAt: string
  updatedAt: string
}

export interface Attributes {
  PreOrderID: string
  Token: string
}

export const extOrdersApiResponseSuccess = (actionType: string, data: ExtOrdersType | {}, page?: Page): ExtOrdersActionType => ({
  type: ExtOrdersActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data, page },
});

export const extOrdersApiResponseError = (actionType: string, data: ExtOrdersType | {}): ExtOrdersActionType => ({
  type: ExtOrdersActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, data },
});

export const listExtOrders = (page: number = 0, pageSize: number = 20): ExtOrdersActionType => ({
  type: ExtOrdersActionTypes.LIST_EXTORDERS,
  payload: {
    page,
    pageSize
  },
})

export const listEnterpriseExtOrders = (enterprise_id?: string | number, page: number = 0, pageSize: number = 20): ExtOrdersActionType => ({
  type: ExtOrdersActionTypes.LIST_ENTERPRISEEXTORDERS,
  payload: {
    enterprise_id,
    page,
    pageSize
  },
})

export const resetExtOrdersType = (): ExtOrdersActionType => ({
  type: ExtOrdersActionTypes.RESET,
  payload: {},
})