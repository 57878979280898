import { Page } from "redux/common";
import { ProductActionTypes } from "./constants";

export type ProductActionType = {
  type:
  | ProductActionTypes.API_RESPONSE_SUCCESS
  | ProductActionTypes.API_RESPONSE_ERROR
  | ProductActionTypes.LIST_PRODUCTS
  | ProductActionTypes.SYNC_PRODUCT
  | ProductActionTypes.DELETE_PRODUCT
  | ProductActionTypes.RESET
  payload: {} | string;
}

export interface ProductType {
  id: number
  enterpriseId: number
  provider: string
  sourceId: string
  name: string
  username: string
  telephone: string
  clickId: string
  items: any[]
  total: number
  price: number
  quantity: number
  createdTime: string
  updatedTime: string
}

export const productApiResponseSuccess = (actionType: string, data: ProductType | {}, page?: Page): ProductActionType => ({
  type: ProductActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data, page },
});

export const productApiResponseError = (actionType: string, data: ProductType | {}): ProductActionType => ({
  type: ProductActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, data },
});

export const listProducts = (enterpriseId: number, page: number = 1, pageSize: number = 20): ProductActionType => ({
  type: ProductActionTypes.LIST_PRODUCTS,
  payload: {
    enterpriseId,
    page,
    pageSize,
  },
})

export const syncProduct = (productId: number): ProductActionType => ({
  type: ProductActionTypes.SYNC_PRODUCT,
  payload: {
    id: productId,
  },
})

export const deleteProduct = (productId: number): ProductActionType => ({
  type: ProductActionTypes.DELETE_PRODUCT,
  payload: {
    id: productId,
  },
})

export const resetProductType = (): ProductActionType => ({
  type: ProductActionTypes.RESET,
  payload: {},
})