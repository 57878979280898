import { Page } from "redux/common";
import { UserActionTypes } from "./constants";

export type UserActionType = {
  type:
  | UserActionTypes.API_RESPONSE_SUCCESS
  | UserActionTypes.API_RESPONSE_ERROR
  | UserActionTypes.LIST_USERS
  | UserActionTypes.CREATE_USER
  | UserActionTypes.DELETE_USER
  | UserActionTypes.RESET
  payload: {} | string;
}

export interface UserType {
  id: number
  username: string
  password: string
  avatar: string
  email: string
  role: string
  roleId: string
  realname: string
  gender: number
  location: string
  loginCount: number
  mobile: string
  state: string
  summary: string
  wechatAccount: string
  createdAt: string
  updatedAt: string
}

export interface CreateUserType {
  username: string;
  password: string;
}

export const userApiResponseSuccess = (actionType: string, data: UserType | {}, page?: Page): UserActionType => ({
  type: UserActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data, page },
});

export const userApiResponseError = (actionType: string, data: UserType | {}): UserActionType => ({
  type: UserActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, data },
});

export const listUsers = (page: number = 1, pageSize: number = 20): UserActionType => ({
  type: UserActionTypes.LIST_USERS,
  payload: {
    page,
    pageSize,
  },
})

export const createUser = (data?: CreateUserType): UserActionType => ({
  type: UserActionTypes.CREATE_USER,
  payload: {
    data
  },
})

export const deleteUser = (userId: number): UserActionType => ({
  type: UserActionTypes.DELETE_USER,
  payload: {
    id: userId
  },
})

export const resetUserType = (): UserActionType => ({
  type: UserActionTypes.RESET,
  payload: {},
})