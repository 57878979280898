import { PushMode, PushConfig } from 'pages/apps/Adset/types';
import { Account } from 'redux/account/actions';
import { Page } from 'redux/common';
import { AdsetActionTypes } from './constants';

export type AdsetActionType = {
    type:
    | AdsetActionTypes.API_RESPONSE_SUCCESS
    | AdsetActionTypes.API_RESPONSE_ERROR
    | AdsetActionTypes.LIST_ADSETS
    | AdsetActionTypes.GET_ADSET
    | AdsetActionTypes.PUT_ADSET
    | AdsetActionTypes.START_ADSET
    | AdsetActionTypes.STOP_ADSET
    | AdsetActionTypes.ACK_PUSH
    | AdsetActionTypes.RELENISH_PUSH
    | AdsetActionTypes.CHANGE_PARAMS
    | AdsetActionTypes.CHANGE_PUSHSTRATEGY
    | AdsetActionTypes.RESET
    payload: {} | string;
};

export interface Adset {
    id: number
    provider: string
    name: string
    ads: any[]
    accountId: number
    creatorId: number
    creator: Creator
    account: Account
    sourceAccountId: string
    enterpriseId: number
    strategyName: string
    strategy: Strategy
    userActionSetId: string
    status: string
    goal: string
    deepGoal: string
    params: Params
    doneActions: any[]
    enterprise: any
    customerServices: any[]
    pushStrategy: PushStrategy
    createdTime: string
    updatedTime: string
}

export interface Creator {
    id: number
    username: string
    password: string
    avatar: string
    email: string
    role: string
    roleId: string
    realname: string
    gender: number
    location: string
    loginCount: number
    mobile: string
    state: string
    summary: string
    wechatAccount: string
    createdAt: string
    updatedAt: string
}

export interface Strategy {
    name: string
    conversionType: string
}

export interface Params {
    scrollX1: number
    scrollX2: number
    scrollX3: number
    stayTimeX1: string
    stayTimeX2: string
    stayTimeX3: string
    retX1: number
    retX2: number
}

export interface PushStrategy {
    mode: PushMode;
    config: PushConfig;
}


export const adsetApiResponseSuccess = (actionType: string, data: Adset | {}, page?: Page, q?: string): AdsetActionType => ({
    type: AdsetActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data, page, q },
});

export const adsetApiResponseError = (actionType: string, data: Adset | {}): AdsetActionType => ({
    type: AdsetActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, data },
});


export const listAdsets = (enterpriseId?: number, page: number = 1, pageSize: number = 20, q?: string): AdsetActionType => ({
    type: AdsetActionTypes.LIST_ADSETS,
    payload: {
        enterpriseId,
        q,
        page,
        pageSize
    },
})

export const getAdset = (adsetId: number | string): AdsetActionType => ({
    type: AdsetActionTypes.GET_ADSET,
    payload: {
        id: adsetId
    },
})

export const putAdset = (adsetId: number | string, data: Adset | {}): AdsetActionType => ({
    type: AdsetActionTypes.PUT_ADSET,
    payload: {
        id: adsetId,
        data: data,
    },
})

export const changeParams = (adsetId: number | string, data: Params | {}): AdsetActionType => ({
    type: AdsetActionTypes.CHANGE_PARAMS,
    payload: {
        id: adsetId,
        data: data,
    },
})

export const changePushStrategy = (adsetId: number | string, data: PushStrategy | {}): AdsetActionType => ({
    type: AdsetActionTypes.CHANGE_PUSHSTRATEGY,
    payload: {
        id: adsetId,
        data: data,
    },
})

export const startAdset = (adsetId: number | string): AdsetActionType => ({
    type: AdsetActionTypes.START_ADSET,
    payload: {
        id: adsetId,
    },
})

export const stopAdset = (adsetId: number | string): AdsetActionType => ({
    type: AdsetActionTypes.STOP_ADSET,
    payload: {
        id: adsetId,
    },
})


export const ackPush = (adsetId: number | string, count: number): AdsetActionType => ({
    type: AdsetActionTypes.ACK_PUSH,
    payload: {
        id: adsetId,
        count: count,
    },
})

export const relenishPush = (adsetId: number | string, count?: number, clickId?: string): AdsetActionType => ({
    type: AdsetActionTypes.RELENISH_PUSH,
    payload: {
        id: adsetId,
        count,
        clickId,
    },
})

export const resetAdset = (): AdsetActionType => ({
    type: AdsetActionTypes.RESET,
    payload: {},
})
