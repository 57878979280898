import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import {
  listFormSchemas as listFormSchemasApi,
  createEnterpriseFormSchema as createEnterpriseFormSchemaApi,
  updateFormSchema as updateFormSchemaApi,
  getFormSchema as getFormSchemaApi
} from 'helpers';
import { formSchemaApiResponseSuccess, formSchemaApiResponseError, FormSchemaType } from './actions';
import { FormSchemaActionTypes } from './constants';

type FormsSchemaPayload = {
  payload: {
    id?: number,
    enterpriseId?: number,
    data: FormSchemaType[] | FormSchemaType | {},
    page?: number
    pageSize?: number
  }
  type: string
}

function* listFormSchemas({ payload: { enterpriseId, page = 1,
  pageSize }, type }: FormsSchemaPayload): SagaIterator {
  try {
    const response = yield call(listFormSchemasApi, enterpriseId, {
      'page.page': page,
      'page.pageSize': pageSize,
    });
    const { schemas, page: {
      totalCounts
    } } = response.data;
    yield put(formSchemaApiResponseSuccess(FormSchemaActionTypes.LIST_FORMSCHEMAS, schemas, {
      pageCount: totalCounts,
    }))
  } catch (error: any) {
    yield put(formSchemaApiResponseError(FormSchemaActionTypes.LIST_FORMSCHEMAS, error));
  }
}

function* createFormSchema({ payload: { enterpriseId, data }, type }: FormsSchemaPayload): SagaIterator {
  try {
    const response = yield call(createEnterpriseFormSchemaApi, enterpriseId, data);
    yield put(formSchemaApiResponseSuccess(FormSchemaActionTypes.CREATE_FORMSCHEMA, response.data))
  } catch (error: any) {
    yield put(formSchemaApiResponseError(FormSchemaActionTypes.CREATE_FORMSCHEMA, error));
  }
}

function* updateFormSchema({ payload: { id, data }, type }: FormsSchemaPayload): SagaIterator {
  try {
    const response = yield call(updateFormSchemaApi, id!, data);
    yield put(formSchemaApiResponseSuccess(FormSchemaActionTypes.UPDATE_FORMSCHEMA, { data, id }))
  } catch (error: any) {
    yield put(formSchemaApiResponseError(FormSchemaActionTypes.UPDATE_FORMSCHEMA, error));
  }
}

function* getFormSchema({ payload: { id }, type }: FormsSchemaPayload): SagaIterator {
  try {
    const response = yield call(getFormSchemaApi, id!);
    yield put(formSchemaApiResponseSuccess(FormSchemaActionTypes.GET_FORMSCHEMA, response.data))
  } catch (error: any) {
    yield put(formSchemaApiResponseError(FormSchemaActionTypes.GET_FORMSCHEMA, error));
  }
}

function* watchListFormSchemas() {
  yield takeEvery(FormSchemaActionTypes.LIST_FORMSCHEMAS, listFormSchemas);
}

function* watchCreateFormSchema() {
  yield takeEvery(FormSchemaActionTypes.CREATE_FORMSCHEMA, createFormSchema);
}

function* watchUpdateFormSchema() {
  yield takeEvery(FormSchemaActionTypes.UPDATE_FORMSCHEMA, updateFormSchema);
}

function* watchGetFormSchema() {
  yield takeEvery(FormSchemaActionTypes.GET_FORMSCHEMA, getFormSchema);
}

function* formSaga() {
  yield all([fork(watchListFormSchemas), fork(watchCreateFormSchema), fork(watchUpdateFormSchema), fork(watchGetFormSchema)])
}

export default formSaga;