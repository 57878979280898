import { entPath } from './adset';
import { APICore } from './apiCore';

const api = new APICore();
const baseUrl = "/api/v1"

export function listForms(enterpriseId?: number, params: {
    'page.page'?: number, // 页起始位置
    'page.pageSize'?: number // 页大小
} = {}) {
    return api.get(entPath("forms", enterpriseId), params);
}

export function deleteForm(formId: number) {
    return api.delete(`${baseUrl}/forms/${formId}`)
}