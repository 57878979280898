import { SettingActionTypes } from "./constants";


const INIT_STATE = {
    settings: {
    },
    schemas: {
    },
    uis: {},
    loading: false,
};

type SettingData = {
    configKey: string;
    schema: {};
    ui: {};
    settings: {};
}

type SettingsActionType = {
    type:
    SettingActionTypes.API_RESPONSE_SUCCESS |
    SettingActionTypes.API_RESPONSE_ERROR |
    SettingActionTypes.GET_SETTINGS |
    SettingActionTypes.SET_SETTINGS;
    payload: {
        actionType?: string;
        data?: SettingData;
        error?: string;
    };
};


type State = {
    settings: {
        [key: string]: any;
    }
    schemas: {
        [key: string]: any;
    }
    uis: {
        [key: string]: any;
    }
    loading: boolean;
}

function parsejson(obj: any) {
    try {
        return JSON.parse(obj);
    } catch (e) {
        return {};
    }
}

const Settings = (state: State = INIT_STATE, action: SettingsActionType) => {
    switch (action.type) {
        case SettingActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case SettingActionTypes.GET_SETTINGS: {
                    const { settings, schemas, uis } = state;
                    const { data } = action.payload;
                    if (data) {
                        return {
                            ...state,
                            settings: {
                                ...settings,
                                [data?.configKey]: parsejson(action.payload.data?.settings),
                            },
                            schemas: {
                                ...schemas,
                                [data?.configKey]: parsejson(action.payload.data?.schema),
                            },
                            uis: {
                                ...uis,
                                [data?.configKey]: parsejson(action.payload.data?.ui),
                            },
                            loading: false,
                        };
                    }
                    else {
                        return {
                            ...state,
                            loading: false,
                        };
                    }
                }
                case SettingActionTypes.SET_SETTINGS: {
                    const { settings } = state;
                    const { data } = action.payload;
                    if (data) {
                        return {
                            ...state,
                            settings: {
                                ...settings,
                                [data?.configKey]: parsejson(action.payload.data?.settings),
                            }
                        }
                    } else {
                        return {
                            ...state,
                            loading: false,
                        }
                    }
                }
                default:
                    return { ...state };
            }
        case SettingActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case SettingActionTypes.GET_SETTINGS: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case SettingActionTypes.SET_SETTINGS: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }
        case SettingActionTypes.GET_SETTINGS: {
            return {
                ...state,
                loading: true,
            };
        }
        case SettingActionTypes.SET_SETTINGS: {
            return {
                ...state,
                loading: true
            }
        }
        default:
            return { ...state };
    }
}


export default Settings;