import { Page } from 'redux/common';
import { WxCustomerActionTypes } from './constants'

export type WxCustomerActionType = {
  type:
  | WxCustomerActionTypes.API_RESPONSE_SUCCESS
  | WxCustomerActionTypes.API_RESPONSE_ERROR
  | WxCustomerActionTypes.LIST_WXCUSTOMERS
  | WxCustomerActionTypes.RESET

  payload: {} | string;
};

export interface WxCustomer {
  id: number
  corpId: string
  openKfid: string
  name: string
  avatar: string
  scene: string
  kfUrl: string
  mediaId: string
  enterpriseId: number
}

export const wxCustmoerApiResponseSuccess = (actionType: string, data: WxCustomer | {}, page?: Page): WxCustomerActionType => ({
  type: WxCustomerActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data, page },
});

export const wxCustmoerApiResponseError = (actionType: string, error: Error): WxCustomerActionType => ({
  type: WxCustomerActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const listWxCustomers = (corp_id: string, page: number = 1, pageSize: number = 20): WxCustomerActionType => ({
  type: WxCustomerActionTypes.LIST_WXCUSTOMERS,
  payload: {
    corp_id,
    page,
    pageSize,
  },
})

export const resetWxCustomer = (): WxCustomerActionType => ({
  type: WxCustomerActionTypes.RESET,
  payload: {},
})