import { Page } from 'redux/common';
import { AuthorizedEnterpriseActionTypes } from './constants'

export type AuthorizedEnterpriseActionType = {
  type:
  | AuthorizedEnterpriseActionTypes.API_RESPONSE_SUCCESS
  | AuthorizedEnterpriseActionTypes.API_RESPONSE_ERROR
  | AuthorizedEnterpriseActionTypes.LIST_AUTHORIZEDENTERPRISES
  | AuthorizedEnterpriseActionTypes.RESET

  payload: {} | string;
};

export interface AuthorizedEnterprise {
  id: number
  accessToken: string
  accessTokenExpire: any
  corpid: string
  corpName: string
  corpType: string
  corpSquareLogoUrl: string
  corpUserMax: number
  corpFullName: string
  verifiedEndTime: string
  subjectType: number
  corpWxqrcode: string
  corpScale: string
  corpIndustry: string
  corpSubIndustry: string
  suiteId: string
  permanentCode: string
  agents: any[]
  authUserId: string
  authOpenUserId: string
  authUserName: string
  authUserAvatar: string
  dealCorpid: string
  dealCorpName: string
  enterpriseId: number
}

export const authorizedEnterpriseApiResponseSuccess = (actionType: string, data: AuthorizedEnterprise | {}, page?: Page): AuthorizedEnterpriseActionType => ({
  type: AuthorizedEnterpriseActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data, page },
});

export const authorizedEnterpriseApiResponseError = (actionType: string, data: AuthorizedEnterprise | {}): AuthorizedEnterpriseActionType => ({
  type: AuthorizedEnterpriseActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, data },
});

export const listAuthorizedEnterprises = (enterpriseId?: number, page: number = 1, pageSize: number = 20): AuthorizedEnterpriseActionType => ({
  type: AuthorizedEnterpriseActionTypes.LIST_AUTHORIZEDENTERPRISES,
  payload: {
    enterpriseId,
    page,
    pageSize,
  },
})

export const resetAuthorizedEnterprise = (): AuthorizedEnterpriseActionType => ({
  type: AuthorizedEnterpriseActionTypes.RESET,
  payload: {},
})