import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import {
  listAuthorizedEnterprises as listAuthorizedEnterprisesApi,
} from 'helpers';
import { authorizedEnterpriseApiResponseSuccess, authorizedEnterpriseApiResponseError, AuthorizedEnterprise } from './actions';
import { AuthorizedEnterpriseActionTypes } from './constants';

type AuthorizedEnterprisePayload = {
  payload: {
    id?: number,
    enterpriseId?: number
    data: AuthorizedEnterprise[] | AuthorizedEnterprise | {};
    page?: number,
    pageSize?: number,
    adsetId?: number,
  }
  type: string
}

function* listAuthorizedEnterprises({ payload: { enterpriseId, page = 1, pageSize }, type }: AuthorizedEnterprisePayload): SagaIterator {
  try {
    const response = yield call(listAuthorizedEnterprisesApi, enterpriseId);
    const { authCorps, total } = response;
    const pageCount = Math.ceil(total / 20)
    yield put(authorizedEnterpriseApiResponseSuccess(AuthorizedEnterpriseActionTypes.LIST_AUTHORIZEDENTERPRISES, authCorps, { pageCount }))
  } catch (error: any) {
    yield put(authorizedEnterpriseApiResponseError(AuthorizedEnterpriseActionTypes.LIST_AUTHORIZEDENTERPRISES, error));
  }
}

function* watchListWxEnterprises() {
  yield takeEvery(AuthorizedEnterpriseActionTypes.LIST_AUTHORIZEDENTERPRISES, listAuthorizedEnterprises);
}

function* authorizedEnterpriseSaga() {
  yield all([fork(watchListWxEnterprises)])
}

export default authorizedEnterpriseSaga;