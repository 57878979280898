import { First } from "react-bootstrap/esm/PageItem";

export interface SliceElement {
    id: number;
}

function sliceFind<SliceElement>(slice: Array<SliceElement>, id: number): number {
    let idx = slice.findIndex((value: any) => value.id === id);
    return idx < 0 ? slice.length : idx;
}

export type FieldItem = {
    [name: string]: any;
}

export type FilterItem = {
    ID: string;
    Value: any;
}

function buildFilters(...fields: FieldItem[]): string[] {
    var filters: string[] = [];
    const firstKey = (field: FieldItem) => {
        for (let key in field) {
            return key
        }
        return ''
    }

    const firstValue = (field: FieldItem) => {
        for (let key in field) {
            return field[key]
        }
        return null
    }


    for (let field of fields) {
        if (firstValue(field)) {
            filters.push(JSON.stringify({ "ID": firstKey(field), "Value": firstValue(field) }))
        }
    }

    return filters
}



export {
    sliceFind,
    buildFilters,
}