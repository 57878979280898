import { Page } from 'redux/common';
import { EnterpriseActionTypes } from "./constants";
import { EnterpriseType, Members } from "./actions";

const INIT_STATE = {
  enterprises: [],
  members: [],
  loading: false,
};

type EnterpriseActionType = {
  type:
  | EnterpriseActionTypes.API_RESPONSE_SUCCESS
  | EnterpriseActionTypes.API_RESPONSE_ERROR
  | EnterpriseActionTypes.LIST_ENTERPRISES
  | EnterpriseActionTypes.CREATE_ENTERPRISE
  | EnterpriseActionTypes.DELETE_ENTERPRISE
  | EnterpriseActionTypes.LIST_MEMBERS
  | EnterpriseActionTypes.DELETE_MEMBER
  | EnterpriseActionTypes.ADD_MEMBER
  | EnterpriseActionTypes.RESET
  payload: {
    actionType?: string;
    data?: EnterpriseType[] | EnterpriseType | Members[] | {};
    error?: string;
    id?: number;
    page: Page,
  }
}

type State = {
  enterprises?: EnterpriseType[];
  members?: Members[];
  loading?: boolean;
  error?: string
};

const findSlice = (data: EnterpriseType[] | Members[], enterpriseId: number): number => {
  let idx = data.findIndex((a) => a.id === enterpriseId);
  return idx < 0 ? data.length : idx;
}

const Enterprises = (state: State = INIT_STATE, action: EnterpriseActionType) => {
  switch (action.type) {
    case EnterpriseActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case EnterpriseActionTypes.LIST_ENTERPRISES: {
          return {
            ...state,
            enterprises: action.payload.data,
            loading: false,
            page: action.payload.page,
          }
        }
        case EnterpriseActionTypes.CREATE_ENTERPRISE: {
          let { enterprises = [] } = state
          return {
            ...state,
            enterprises: [...enterprises, action.payload.data],
            loading: false,
          }
        }
        case EnterpriseActionTypes.DELETE_ENTERPRISE: {
          let { enterprises = [] } = state,
            idx = findSlice(enterprises, (action.payload.data as EnterpriseType).id);
          enterprises.splice(idx, 1)
          return {
            ...state,
            enterprises: [...enterprises],
            loading: false,
          }
        }
        case EnterpriseActionTypes.LIST_MEMBERS: {
          return {
            ...state,
            members: action.payload.data,
            loading: false,
            page: action.payload.page,
          }
        }
        case EnterpriseActionTypes.DELETE_MEMBER: {
          const { members = [] } = state,
            idx = findSlice(members, (action.payload.data as Members).id);
          members.splice(idx, 1);
          return {
            ...state,
            members: [...members],
            loading: false,
          }
        }
        case EnterpriseActionTypes.ADD_MEMBER: {
          let { members = [] } = state,
            idx = findSlice(members, (action.payload.data as Members).id);
          return {
            ...state,
            members: [...members.slice(0, idx), action.payload.data, ...members.slice(idx + 1)],
            loading: false,
          }
        }
        default:
          return { ...state };
      }
    case EnterpriseActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case EnterpriseActionTypes.LIST_ENTERPRISES: {
          return {
            ...state,
            error: action.payload.error,
            loading: false
          }
        }
        case EnterpriseActionTypes.CREATE_ENTERPRISE: {
          return {
            ...state,
            error: action.payload.error,
            loading: false
          }
        }
        case EnterpriseActionTypes.DELETE_ENTERPRISE: {
          return {
            ...state,
            error: action.payload.error,
            loading: false
          }
        }
        case EnterpriseActionTypes.LIST_MEMBERS: {
          return {
            ...state,
            error: action.payload.error,
            loading: false
          }
        }
        case EnterpriseActionTypes.DELETE_MEMBER: {
          return {
            ...state,
            error: action.payload.error,
            loading: false
          }
        }
        case EnterpriseActionTypes.ADD_MEMBER: {
          return {
            ...state,
            error: action.payload.error,
            loading: false
          }
        }
        default:
          return { ...state }
      }
    case EnterpriseActionTypes.LIST_ENTERPRISES:
      return { ...state, loading: true }
    case EnterpriseActionTypes.CREATE_ENTERPRISE:
      return { ...state, loading: true }
    case EnterpriseActionTypes.DELETE_ENTERPRISE:
      return { ...state, loading: true }
    case EnterpriseActionTypes.LIST_MEMBERS:
      return { ...state, loading: true }
    case EnterpriseActionTypes.DELETE_MEMBER:
      return { ...state, loading: true }
    case EnterpriseActionTypes.ADD_MEMBER:
      return { ...state, loading: true }
    case EnterpriseActionTypes.RESET:
      return {
        ...state,
        ...INIT_STATE,
      }
    default:
      return { ...state };
  }
}

export default Enterprises