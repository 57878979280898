import { APICore } from './apiCore';

const api = new APICore();
const baseUrl = "/api/v1"

export function listUsers(q?: string, page?: number, pageSize?: number) {
  let filters = []
  if (q) {
    filters.push(JSON.stringify({ "ID": "Q", "Value": q }))
  }
  const params = {
    'page.page': page || 0,
    'page.pageSize': pageSize || 0,
    filters
  }
  return api.get(`${baseUrl}/users`, params);
}

export function createUser(data: any) {
  return api.create(`${baseUrl}/users`, data);
}

export function deleteUser(userId: number | string) {
  return api.delete(`${baseUrl}/users/${userId}`);
}

export function changePassword(userId: number | string, newPassword: string, oldPassword?: string) {
  return api.update(`${baseUrl}/users/${userId}/password`,
    { 'newPassword': newPassword, 'oldPassword': oldPassword });
}