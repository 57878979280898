import {
    listAdsets as listAdsetsApi,
    getAdset as getAdsetApi,
    putAdset as putAdsetApi,
    ackPush as ackPushApi,
    relenishPush as relenishPushApi,
    changeAdsetState as changeAdsetStateApi,
} from 'helpers';
import { SagaIterator } from 'redux-saga';
import { all, fork, call, takeEvery, put } from 'redux-saga/effects';
import { Adset, adsetApiResponseError, adsetApiResponseSuccess } from './actions';
import { AdsetActionTypes } from './constants';

type AdsetPayload = {
    payload: {
        enterpriseId?: number;
        page?: number
        pageSize?: number
        q?: string;
        id?: number
        count?: number
        clickId: string
        data: Adset | {}
    };
    type: string;
};

function* listAdsets({ payload: { enterpriseId, q, page = 1,
    pageSize, }, type }: AdsetPayload): SagaIterator {
    try {
        const response = yield call(listAdsetsApi, enterpriseId, page, pageSize, q);
        const { adsets, page: {
            totalCounts
        } } = response.data;
        yield put(adsetApiResponseSuccess(AdsetActionTypes.LIST_ADSETS, adsets, {
            pageCount: totalCounts,
        }, q))
    } catch (error: any) {
        yield put(adsetApiResponseError(AdsetActionTypes.LIST_ADSETS, error));
    }
}

function* getAdset({ payload: { id }, type }: AdsetPayload): SagaIterator {
    try {
        const response = yield call(getAdsetApi, id!)
        const { adset } = response.data;
        yield put(adsetApiResponseSuccess(AdsetActionTypes.GET_ADSET, adset))
    } catch (error: any) {
        yield put(adsetApiResponseError(AdsetActionTypes.GET_ADSET, error));
    }
}

function* putAdset({ payload: { id, data }, type }: AdsetPayload): SagaIterator {
    try {
        const response = yield call(putAdsetApi, id!, data)
        const { adset } = response.data;
        yield put(adsetApiResponseSuccess(AdsetActionTypes.PUT_ADSET, adset))
    } catch (error: any) {
        yield put(adsetApiResponseError(AdsetActionTypes.PUT_ADSET, error));
    }
}

function* changeParams({ payload: { id, data }, type }: AdsetPayload): SagaIterator {
    try {
        yield call(putAdsetApi, id!, { params: data })
        yield put(adsetApiResponseSuccess(AdsetActionTypes.CHANGE_PARAMS, { id, params: data }))
    } catch (error: any) {
        yield put(adsetApiResponseError(AdsetActionTypes.CHANGE_PARAMS, error));
    }
}

function* changePushStrategy({ payload: { id, data }, type }: AdsetPayload): SagaIterator {
    try {
        yield call(putAdsetApi, id!, { pushStrategy: data })
        yield put(adsetApiResponseSuccess(AdsetActionTypes.CHANGE_PUSHSTRATEGY, { id, pushStrategy: data }))
    } catch (error: any) {
        yield put(adsetApiResponseError(AdsetActionTypes.CHANGE_PUSHSTRATEGY, error));
    }
}

function* startAdset({ payload: { id }, type }: AdsetPayload): SagaIterator {
    try {
        const response = yield call(changeAdsetStateApi, id!, 'AD_RUNNING')
        const { adset
        } = response.data;
        yield put(adsetApiResponseSuccess(AdsetActionTypes.START_ADSET, adset))
    } catch (error: any) {
        yield put(adsetApiResponseError(AdsetActionTypes.START_ADSET, error));
    }
}

function* stopAdset({ payload: { id }, type }: AdsetPayload): SagaIterator {
    try {
        const response = yield call(changeAdsetStateApi, id!, 'AD_STOP')
        const { adset } = response.data;
        yield put(adsetApiResponseSuccess(AdsetActionTypes.STOP_ADSET, adset))
    } catch (error: any) {
        yield put(adsetApiResponseError(AdsetActionTypes.STOP_ADSET, error));
    }
}

function* ackPush({ payload: { id, count }, type }: AdsetPayload): SagaIterator {
    try {
        const response = yield call(ackPushApi, id!, count!)
        const { adset } = response.data;
        yield put(adsetApiResponseSuccess(AdsetActionTypes.ACK_PUSH, adset))
    } catch (error: any) {
        yield put(adsetApiResponseError(AdsetActionTypes.ACK_PUSH, error));
    }
}

function* relenishPush({ payload: { id, count, clickId }, type }: AdsetPayload): SagaIterator {
    try {
        const response = yield call(relenishPushApi, id!, count, clickId)
        const { adset } = response.data;
        yield put(adsetApiResponseSuccess(AdsetActionTypes.RELENISH_PUSH, adset))
    } catch (error: any) {
        yield put(adsetApiResponseError(AdsetActionTypes.RELENISH_PUSH, error));
    }
}

function* watchListAdsets() {
    yield takeEvery(AdsetActionTypes.LIST_ADSETS, listAdsets);
}

function* watchGetAdset() {
    yield takeEvery(AdsetActionTypes.GET_ADSET, getAdset)
}

function* watchPutAdset() {
    yield takeEvery(AdsetActionTypes.PUT_ADSET, putAdset)
}

function* watchChangeParams() {
    yield takeEvery(AdsetActionTypes.CHANGE_PARAMS, changeParams)
}

function* watchStartAdset() {
    yield takeEvery(AdsetActionTypes.START_ADSET, startAdset)
}

function* watchStopAdset() {
    yield takeEvery(AdsetActionTypes.STOP_ADSET, stopAdset)
}

function* watchAckPush() {
    yield takeEvery(AdsetActionTypes.ACK_PUSH, ackPush)
}

function* watchRelenishPush() {
    yield takeEvery(AdsetActionTypes.RELENISH_PUSH, relenishPush)
}

function* watchChangePushStrategy() {
    yield takeEvery(AdsetActionTypes.CHANGE_PUSHSTRATEGY, changePushStrategy)
}


function* adsetSaga() {
    yield all([
        fork(watchListAdsets),
        fork(watchGetAdset),
        fork(watchPutAdset),
        fork(watchStartAdset),
        fork(watchStopAdset),
        fork(watchAckPush),
        fork(watchRelenishPush),
        fork(watchChangeParams),
        fork(watchChangePushStrategy)
    ])
}

export default adsetSaga;