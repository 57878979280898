import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import {
  listExtOrders as listExtOrdersApi,
} from 'helpers';
import { extOrdersApiResponseSuccess, extOrdersApiResponseError, ExtOrdersType } from './actions';
import { ExtOrdersActionTypes } from './constants';

type ExtOrdersPayload = {
  payload: {
    id?: number,
    enterprise_id?: number | string,
    data: ExtOrdersType[] | ExtOrdersType | {},
    page?: number
    pageSize?: number
  }
  type: string
}

function* listExtOrders({ payload: { page = 0,
  pageSize }, type }: ExtOrdersPayload): SagaIterator {
  try {
    const response = yield call(listExtOrdersApi, {
      'page': page,
      'pageSize': pageSize,
    });
    const { orders, total } = response;
    const pageCount = Math.ceil(total / 20)
    yield put(extOrdersApiResponseSuccess(ExtOrdersActionTypes.LIST_EXTORDERS, orders, { pageCount }))
  } catch (error: any) {
    yield put(extOrdersApiResponseError(ExtOrdersActionTypes.LIST_EXTORDERS, error));
  }
}

function* listEnterpriseExtOrders({ payload: { enterprise_id, page = 0,
  pageSize }, type }: ExtOrdersPayload): SagaIterator {
  try {
    const response = yield call(listExtOrdersApi, {
      'enterprise_id': enterprise_id,
      'page': page,
      'pageSize': pageSize,
    });
    const { orders, total } = response;
    const pageCount = Math.ceil(total / 20)
    yield put(extOrdersApiResponseSuccess(ExtOrdersActionTypes.LIST_ENTERPRISEEXTORDERS, orders, { pageCount }))
  } catch (error: any) {
    yield put(extOrdersApiResponseError(ExtOrdersActionTypes.LIST_ENTERPRISEEXTORDERS, error));
  }
}

function* watchListOrders() {
  yield takeEvery(ExtOrdersActionTypes.LIST_EXTORDERS, listExtOrders);
}

function* watchListEnterpriseOrders() {
  yield takeEvery(ExtOrdersActionTypes.LIST_ENTERPRISEEXTORDERS, listEnterpriseExtOrders);
}


function* extOrdersSaga() {
  yield all([fork(watchListOrders), fork(watchListEnterpriseOrders)])
}

export default extOrdersSaga;