export enum EnterpriseActionTypes {
  API_RESPONSE_SUCCESS = '@@enterprise/API_RESPONSE_SUCCESS',
  API_RESPONSE_ERROR = '@@enterprise/API_RESPONSE_ERROR',

  LIST_ENTERPRISES = '@@enterprise/LIST_ENTERPRISES',
  CREATE_ENTERPRISE = '@@enterprise/CREATE_ENTERPRISE',
  DELETE_ENTERPRISE = '@@enterprise/DELETE_ENTERPRISE',
  LIST_MEMBERS = '@@enterprise/LIST_MEMBERS',
  DELETE_MEMBER = '@@enterprise/DELETE_MEMBER',
  ADD_MEMBER = '@@enterprise/ADD_MEMBER',

  RESET = '@@enterprise/RESET',
}