import { Page } from 'redux/common';
import { CustomerAcquisition } from './actions';
import { CustomerAcquisitionsActionTypes } from './constants';

const INIT_STATE = {
  customerAcquisitions: [],
  error: '',
  loading: false,
};

type CustomerAcquisitionsActionType = {
  type:
  | CustomerAcquisitionsActionTypes.API_RESPONSE_SUCCESS
  | CustomerAcquisitionsActionTypes.API_RESPONSE_ERROR
  | CustomerAcquisitionsActionTypes.LIST_CUSTOMERACQUISITIONS
  | CustomerAcquisitionsActionTypes.RESET

  payload: {
    actionType?: string;
    data?: CustomerAcquisition[] | CustomerAcquisition | {};
    error?: string;
    id?: number;
    page: Page;
  }
}

type State = {
  customerAcquisitions?: CustomerAcquisition[];
  loading?: boolean;
  error?: string
};

const findSlice = (data: CustomerAcquisition[], accountListId: number): number => {
  let idx = data.findIndex((a) => a.id === accountListId);
  return idx < 0 ? data.length : idx;
}

const CustomerAcquisitions = (state: State = INIT_STATE, action: CustomerAcquisitionsActionType) => {
  switch (action.type) {
    case CustomerAcquisitionsActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case CustomerAcquisitionsActionTypes.LIST_CUSTOMERACQUISITIONS: {
          return {
            ...state,
            customerAcquisitions: action.payload.data,
            page: action.payload.page,
            loading: false,
          }
        }
        default:
          return { ...state };
      }
    case CustomerAcquisitionsActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case CustomerAcquisitionsActionTypes.LIST_CUSTOMERACQUISITIONS: {
          return {
            ...state,
            error: (action.payload.error as any).message,
            loding: false,
          }
        }
        default:
          return { ...state };
      }
    case CustomerAcquisitionsActionTypes.LIST_CUSTOMERACQUISITIONS:
      return { ...state, loading: true }
    case CustomerAcquisitionsActionTypes.RESET:
      return {
        ...state,
        ...INIT_STATE,
      }
    default:
      return { ...state };
  }
}

export default CustomerAcquisitions;
