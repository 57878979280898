import { User } from 'redux/actions';
import { Page } from 'redux/common';
import { AccountActionTypes } from './constants';

export type AccountActionType = {
    type:
    | AccountActionTypes.API_RESPONSE_SUCCESS
    | AccountActionTypes.API_RESPONSE_ERROR
    | AccountActionTypes.LIST_ACCOUNTS
    | AccountActionTypes.GET_ACCOUNT
    | AccountActionTypes.LIST_USER_ACTION_SETS
    | AccountActionTypes.LIST_AGENT_ACCOUNTS
    | AccountActionTypes.RESET
    payload: {
        actionType?: string,
        accountId?: number,
        data: any,
    } | {} | string;
};

export interface Account {
    id: number
    uid: string
    accountId: string
    name: string
    website: string
    corpName: string
    license: string
    licensePicture: string
    Identity: string
    city: string
    contactPerson: string
    contactPersonId: string
    contactPersonEmail: string
    contactPersonPhone: string
    industry: string
    provider: string
    accessToken: string
    accessTokenExpiresIn: string
    refreshToken: string
    refreshTokenExpiresIn: string
    userId: number
    user: User
    enterpriseId: number
    enterprise: any
    createdAt: string
    updatedAt: string
}

export interface UserActionSet {
    activateStatus: boolean
    createdTime: string
    description: string
    enableConversionClaim: boolean
    name: string
    type: string
    usages: string[]
    userActionSetId: string
}


export const accountApiResponseSuccess = (actionType: string, data: Account | {}, page?: Page, provider?: string): AccountActionType => ({
    type: AccountActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data, page, provider },
});

export const accountApiResponseError = (actionType: string, data: Account | {}): AccountActionType => ({
    type: AccountActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, data },
});

export const resetAccount = (): AccountActionType => ({
    type: AccountActionTypes.RESET,
    payload: {},
})

export const listAccounts = (enterpriseId?: number, provider?: string, page: number = 1, pageSize: number = 20): AccountActionType => ({
    type: AccountActionTypes.LIST_ACCOUNTS,
    payload: {
        enterpriseId,
        provider,
        page,
        pageSize
    },
})

export const listUserActionSets = (accountId: number) => ({
    type: AccountActionTypes.LIST_USER_ACTION_SETS,
    payload: {
        id: accountId,
    }
})

export const listAgentAccounts = (accountId: number) => ({
    type: AccountActionTypes.LIST_AGENT_ACCOUNTS,
    payload: {
        id: accountId,
    }
})