import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import {
  listKfAccounts as listWxCustomersApi,
} from 'helpers';
import { wxCustmoerApiResponseSuccess, wxCustmoerApiResponseError, WxCustomer } from './actions';
import { WxCustomerActionTypes } from './constants';

type WxCustomerPayload = {
  payload: {
    id?: number,
    enterpriseId?: number
    data: WxCustomer[] | WxCustomer | {};
    page?: number,
    pageSize?: number,
    corp_id: string
  }
  type: string
}

function* listWxCustomers({ payload: { corp_id, page = 1, pageSize }, type }: WxCustomerPayload): SagaIterator {
  try {
    const response = yield call(listWxCustomersApi, corp_id);
    const { accountList } = response;
    yield put(wxCustmoerApiResponseSuccess(WxCustomerActionTypes.LIST_WXCUSTOMERS, accountList))
  } catch (error: any) {
    yield put(wxCustmoerApiResponseError(WxCustomerActionTypes.LIST_WXCUSTOMERS, error));
  }
}

function* watchListWxCustomers() {
  yield takeEvery(WxCustomerActionTypes.LIST_WXCUSTOMERS, listWxCustomers);
}

function* wxCustomerSaga() {
  yield all([fork(watchListWxCustomers)])
}

export default wxCustomerSaga;