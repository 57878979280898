import { Page } from 'redux/common';
import { FormType } from './actions';
import { FormsActionTypes } from './constants';

const INIT_STATE = {
  forms: [],
  loading: false,
};

type FormActionType = {
  type:
  | FormsActionTypes.API_RESPONSE_SUCCESS
  | FormsActionTypes.API_RESPONSE_ERROR
  | FormsActionTypes.LIST_FORM
  | FormsActionTypes.CREATE_FORM
  | FormsActionTypes.UPDATE_FORM
  | FormsActionTypes.DELETE_FORM
  | FormsActionTypes.GET_FORM
  | FormsActionTypes.RESET

  payload: {
    actionType?: string;
    data?: FormType[] | FormType | {};
    error?: string;
    id?: number;
    page: Page,
  }
}

type State = {
  forms?: FormType[];
  loading?: boolean;
  error?: string
};

const findSlice = (data: FormType[], formId: number): number => {
  let idx = data.findIndex((a) => a.id === formId);
  return idx < 0 ? data.length : idx;
}

const Forms = (state: State = INIT_STATE, action: FormActionType) => {
  switch (action.type) {
    case FormsActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case FormsActionTypes.LIST_FORM: {
          return {
            ...state,
            forms: action.payload.data,
            page: action.payload.page,
            loading: false,
          }
        }
        case FormsActionTypes.CREATE_FORM: {
          let { forms = [] } = state
          // let newForms = forms.unshift(action.payload.data! as Form)
          return {
            ...state,
            forms: [action.payload.data, ...forms]
          }
        }
        case FormsActionTypes.UPDATE_FORM: {
          let { forms = [] } = state,
            idx = findSlice(forms, action.payload.id!);
          return {
            ...state,
            forms: [...forms.slice(0, idx), action.payload.data, ...forms.slice(idx + 1)],
            loading: false,
          }
        }
        case FormsActionTypes.GET_FORM: {
          let { forms = [] } = state,
            idx = findSlice(forms, action.payload.id!);
          return {
            ...state,
            forms: [...forms.slice(0, idx), action.payload.data, ...forms.slice(idx + 1)],
            loading: false,
          }
        }
        case FormsActionTypes.DELETE_FORM: {
          let { forms = [] } = state,
            idx = findSlice(forms, action.payload.id!);
          return {
            ...state,
            forms: forms.splice(idx, 1),
            loading: false,
          }
        }
        default:
          return { ...state };
      }
    case FormsActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case FormsActionTypes.LIST_FORM: {
          return {
            ...state,
            error: action.payload.error,
            laoding: false,
          }
        }
        default:
          return { ...state };
      }
    case FormsActionTypes.LIST_FORM:
      return { ...state, loading: true }
    case FormsActionTypes.CREATE_FORM:
      return { ...state, loading: true }
    case FormsActionTypes.UPDATE_FORM:
      return { ...state, loading: true }
    case FormsActionTypes.DELETE_FORM:
      return { ...state, loading: true }
    case FormsActionTypes.GET_FORM:
      return { ...state, loading: true }
    case FormsActionTypes.RESET:
      return {
        ...state,
        ...INIT_STATE,
      }
    default:
      return { ...state };
  }
}

export default Forms;
