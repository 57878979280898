import { entPath } from './adset';
import { APICore } from './apiCore';

const api = new APICore();
const baseUrl = "/api/v1"

export function entCustPath(path: string, enterpriseId?: number, customerId?: number): string {
  return enterpriseId ? `${baseUrl}/enterprise/${enterpriseId}/${path}/${customerId}` : `${baseUrl}/${path}`;
}

const listCustomers = (enterpriseId?: number, params: {
  'page.page'?: number, // 页起始位置
  'page.pageSize'?: number, // 页大小,
  'filters'?: any[]
} = {}) => {
  return api.get(entPath("customerservice", enterpriseId), params);
}

const createCustomer = (enterpriseId?: number, data?: any,) => {
  return api.create(entPath("customerservice", enterpriseId), data);
}

const batchCreateEnterpriseCustomerServices = (enterpriseId?: number, data?: any,) => {
  return api.create(entPath("batch_customerservices", enterpriseId), data);
}

const listEnterpriseCustomers = (enterpriseId?: number, weixin?: string) => {
  let filters = [];
  if (weixin) {
    filters.push(JSON.stringify({ "ID": "Weixin", "Value": weixin }))
  }
  return api.get(entPath("customerservice", enterpriseId), { filters })
}

const appendCustomers = (data: any, enterpriseId?: number) => {
  return api.update(entPath("customerservice/adsets", enterpriseId), data);
}

const replaceCustomer = (data: any, enterpriseId?: number) => {
  return api.update(entPath("customerservice/replace", enterpriseId), data);
}

const deleteCustomer = (enterpriseId?: number, customerId?: number) => {
  return api.delete(entCustPath("customerservice", enterpriseId, customerId));
}

const filesUpload = (data: any) => {
  return api.createWithFile(`${baseUrl}/files`, { attachment: data })
}

const manyFilesUpload = (files: File[]) => {
  return api.createWithFiles(`${baseUrl}/files`, 'attachment', files)
}

const updateCustomerService = (enterpriseId?: number, customerId?: number, data?: any) => {
  return api.update(entCustPath("customerservice", enterpriseId, customerId), data);
}

const getCustomerService = (enterpriseId?: number, customerId?: number) => {
  return api.get(entCustPath("customerservice", enterpriseId, customerId));
}

export { listCustomers, appendCustomers, createCustomer, listEnterpriseCustomers, replaceCustomer as replaceCustomers, deleteCustomer, filesUpload, manyFilesUpload, batchCreateEnterpriseCustomerServices, updateCustomerService, getCustomerService }