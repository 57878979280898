import { Page } from 'redux/common';
import { ProductActionTypes } from "./constants";
import { ProductType } from "./actions";
import { Product } from 'pages/apps/Product/type';

const INIT_STATE = {
  products: [],
  loading: false,
};

type ProductActionType = {
  type:
  | ProductActionTypes.API_RESPONSE_SUCCESS
  | ProductActionTypes.API_RESPONSE_ERROR
  | ProductActionTypes.LIST_PRODUCTS
  | ProductActionTypes.SYNC_PRODUCT
  | ProductActionTypes.DELETE_PRODUCT
  | ProductActionTypes.RESET
  payload: {
    actionType?: string;
    data?: ProductType[] | ProductType | {};
    error?: string;
    id?: number;
    page: Page,
  }
}

type State = {
  products?: ProductType[];
  loading?: boolean;
  error?: string
};

const findSlice = (data: ProductType[], productId: number): number => {
  let idx = data.findIndex((a) => a.id === productId);
  return idx < 0 ? data.length : idx;
}

const Products = (state: State = INIT_STATE, action: ProductActionType) => {
  switch (action.type) {
    case ProductActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case ProductActionTypes.LIST_PRODUCTS: {
          return {
            ...state,
            products: action.payload.data,
            loading: false,
            page: action.payload.page,
          }
        }
        case ProductActionTypes.SYNC_PRODUCT: {
          let { products = [] } = state,
            idx = findSlice(products, (action.payload.data as Product).id);
          return {
            ...state,
            products: [...products.slice(0, idx), action.payload.data, ...products.slice(idx + 1)],
            loading: false,
          }
        }
        case ProductActionTypes.DELETE_PRODUCT: {
          let { products = [] } = state,
            idx = findSlice(products, (action.payload.data as Product).id);
          products.splice(idx, 1)
          return {
            ...state,
            products: [...products],
            loading: false,
          }
        }
        default:
          return { ...state };
      }
    case ProductActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case ProductActionTypes.LIST_PRODUCTS: {
          return {
            ...state,
            error: action.payload.error,
            loading: false
          }
        }
        case ProductActionTypes.SYNC_PRODUCT: {
          return {
            ...state,
            loading: false,
            error: action.payload.error,
          }
        }
        case ProductActionTypes.DELETE_PRODUCT: {
          return {
            ...state,
            loading: false,
            error: action.payload.error,
          }
        }
        default:
          return { ...state }
      }
    case ProductActionTypes.LIST_PRODUCTS:
      return { ...state, loading: true }
    case ProductActionTypes.SYNC_PRODUCT:
      return { ...state, loading: true }
    case ProductActionTypes.DELETE_PRODUCT:
      return { ...state, loading: true }
    case ProductActionTypes.RESET:
      return {
        ...state,
        ...INIT_STATE,
      }
    default:
      return { ...state };
  }
}

export default Products