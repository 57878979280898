
import { Page } from 'redux/common';
import { Track } from './actions';
import { TrackActionTypes } from './constants';

const INIT_STATE = {
    tracks: [],
    loading: false,
};

type TrackActionType = {
    type:
    | TrackActionTypes.API_RESPONSE_SUCCESS
    | TrackActionTypes.API_RESPONSE_ERROR
    | TrackActionTypes.LIST_ADSET_TRACKS
    | TrackActionTypes.LIST_ADSET_TIMELINE
    | TrackActionTypes.RESET;
    payload: {
        actionType?: string;
        data?: Track[] | Track | {};
        id?: number;
        error?: string;
        errorCode?: number;
        page: Page
    };
};

type State = {
    tracks?: Track[];
    adsetId?: number | string,
    loading?: boolean;
};

const Tracks = (state: State = INIT_STATE, action: TrackActionType) => {
    switch (action.type) {
        case TrackActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case TrackActionTypes.LIST_ADSET_TRACKS: {
                    return {
                        ...state,
                        tracks: action.payload.data,
                        loading: false
                    }
                }
                case TrackActionTypes.LIST_ADSET_TIMELINE: {
                    return {
                        ...state,
                        timeline: action.payload.data,
                        page: action.payload.page,
                        loading: false
                    }
                }
                default:
                    return { ...state }
            }
        case TrackActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case TrackActionTypes.LIST_ADSET_TRACKS: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false
                    }
                }
                case TrackActionTypes.LIST_ADSET_TIMELINE: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false
                    }
                }
                default:
                    return { ...state }
            }
        case TrackActionTypes.LIST_ADSET_TRACKS:
            return { ...state, loading: true }
        case TrackActionTypes.LIST_ADSET_TIMELINE:
            return { ...state, loading: true }
        case TrackActionTypes.RESET:
            return {
                ...state,
                ...INIT_STATE,
            }
        default:
            return { ...state }
    }
}

export default Tracks;