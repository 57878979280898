import { Page } from 'redux/common';
import { WxCustomer } from './actions';
import { WxCustomerActionTypes } from './constants';

const INIT_STATE = {
  accountList: [],
  error: '',
  loading: false,
};

type WxCustomerActionType = {
  type:
  | WxCustomerActionTypes.API_RESPONSE_SUCCESS
  | WxCustomerActionTypes.API_RESPONSE_ERROR
  | WxCustomerActionTypes.LIST_WXCUSTOMERS
  | WxCustomerActionTypes.RESET

  payload: {
    actionType?: string;
    data?: WxCustomer[] | WxCustomer | {};
    error?: string;
    id?: number;
    page: Page;
  }
}

type State = {
  accountList?: WxCustomer[];
  loading?: boolean;
  error?: string
};

const findSlice = (data: WxCustomer[], accountListId: number): number => {
  let idx = data.findIndex((a) => a.id === accountListId);
  return idx < 0 ? data.length : idx;
}

const WxCustomers = (state: State = INIT_STATE, action: WxCustomerActionType) => {
  switch (action.type) {
    case WxCustomerActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case WxCustomerActionTypes.LIST_WXCUSTOMERS: {
          return {
            ...state,
            accountList: action.payload.data,
            page: action.payload.page,
            loading: false,
          }
        }
        default:
          return { ...state };
      }
    case WxCustomerActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case WxCustomerActionTypes.LIST_WXCUSTOMERS: {
          return {
            ...state,
            error: (action.payload.error as any).message,
            loding: false,
          }
        }
        default:
          return { ...state };
      }
    case WxCustomerActionTypes.LIST_WXCUSTOMERS:
      return { ...state, loading: true }
    case WxCustomerActionTypes.RESET:
      return {
        ...state,
        ...INIT_STATE,
      }
    default:
      return { ...state };
  }
}

export default WxCustomers;
