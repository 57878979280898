import { Page } from 'redux/common';
import { UserActionTypes } from "./constants";
import { UserType } from "./actions";

const INIT_STATE = {
  users: [],
  loading: false,
};

type UserActionType = {
  type:
  | UserActionTypes.API_RESPONSE_SUCCESS
  | UserActionTypes.API_RESPONSE_ERROR
  | UserActionTypes.LIST_USERS
  | UserActionTypes.CREATE_USER
  | UserActionTypes.DELETE_USER
  | UserActionTypes.RESET
  payload: {
    actionType?: string;
    data?: UserType[] | UserType | {};
    error?: string;
    id?: number;
    page: Page,
  }
}

type State = {
  users?: UserType[];
  loading?: boolean;
  error?: string
};

const findSlice = (data: UserType[], userId: number): number => {
  let idx = data.findIndex((a) => a.id === userId);
  return idx < 0 ? data.length : idx;
}

const Users = (state: State = INIT_STATE, action: UserActionType) => {
  switch (action.type) {
    case UserActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case UserActionTypes.LIST_USERS: {
          return {
            ...state,
            users: action.payload.data,
            loading: false,
            page: action.payload.page,
          }
        }
        case UserActionTypes.CREATE_USER: {
          let { users = [] } = state;
          return {
            ...state,
            users: [...users, action.payload.data],
            loading: false,
          }
        }
        case UserActionTypes.DELETE_USER: {
          let { users = [] } = state,
            idx = findSlice(users, (action.payload.data as UserType).id);
          users.splice(idx, 1)
          return {
            ...state,
            users: [...users],
            loading: false,
          }
        }
        default:
          return { ...state };
      }
    case UserActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case UserActionTypes.LIST_USERS: {
          return {
            ...state,
            error: action.payload.error,
            loading: false
          }
        }
        case UserActionTypes.CREATE_USER: {
          return {
            ...state,
            error: action.payload.error,
            loading: false
          }
        }
        case UserActionTypes.DELETE_USER: {
          return {
            ...state,
            error: action.payload.error,
            loading: false
          }
        }
        default:
          return { ...state }
      }
    case UserActionTypes.LIST_USERS:
      return { ...state, loading: true }
    case UserActionTypes.CREATE_USER:
      return { ...state, loading: true }
    case UserActionTypes.DELETE_USER:
      return { ...state, loading: true }
    case UserActionTypes.RESET:
      return {
        ...state,
        ...INIT_STATE,
      }
    default:
      return { ...state };
  }
}

export default Users