import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import {
  listYianOrders as listYianOrdersApi,
} from 'helpers';
import { yianOrdersApiResponseSuccess, yianOrdersApiResponseError, YianOrdersType } from './actions';
import { YianOrdersActionTypes } from './constants';

type YianOrdersPayload = {
  payload: {
    id?: number,
    data: YianOrdersType[] | YianOrdersType | {},
    page?: number
    pageSize?: number
  }
  type: string
}

function* listYianOrders({ payload: { page = 0,
  pageSize }, type }: YianOrdersPayload): SagaIterator {
  try {
    const response = yield call(listYianOrdersApi, {
      'page': page,
      'pageSize': pageSize,
    });
    const { orders, total } = response;
    const pageCount = Math.ceil(total / 20)
    yield put(yianOrdersApiResponseSuccess(YianOrdersActionTypes.LIST_YIANORDERS, orders, { pageCount }))
  } catch (error: any) {
    yield put(yianOrdersApiResponseError(YianOrdersActionTypes.LIST_YIANORDERS, error));
  }
}

function* watchListYianOrders() {
  yield takeEvery(YianOrdersActionTypes.LIST_YIANORDERS, listYianOrders);
}

function* yianOrdersSaga() {
  yield all([fork(watchListYianOrders)])
}

export default yianOrdersSaga;