
import { listAccounts as listAccountsApi, getAccount as getAccountApi, listUserActionSets as listUserActionSetsApi, listAgentAccounts as listAgentAccountsApi } from 'helpers';
import { SagaIterator } from 'redux-saga';
import { all, fork, call, takeEvery, put } from 'redux-saga/effects';
import { Account, accountApiResponseError, accountApiResponseSuccess } from './actions';
import { AccountActionTypes } from './constants';

type AccountPayload = {
    payload: {
        id?: number;
        query?: string;
        count?: number;
        enterpriseId?: number;
        data: Account[] | Account | {};
        page?: number;
        pageSize?: number;
        provider?: string;
    };
    type: string;
};

function* listAccounts({ payload: { enterpriseId, query, provider, page = 1,
    pageSize }, type }: AccountPayload): SagaIterator {

    try {
        const response = yield call(listAccountsApi, enterpriseId, query, provider, page, pageSize)
        const { accounts, page: {
            totalCounts
        }
        } = response.data;
        yield put(accountApiResponseSuccess(AccountActionTypes.LIST_ACCOUNTS, accounts, {
            pageCount: totalCounts
        }, provider))
    } catch (error: any) {
        yield put(accountApiResponseError(AccountActionTypes.LIST_ACCOUNTS, error))
    }
}

function* getAccount({ payload: { id }, type }: AccountPayload): SagaIterator {
    try {
        const response = yield call(getAccountApi, id!);
        const { account } = response.data;
        yield put(accountApiResponseError(AccountActionTypes.GET_ACCOUNT, account))
    } catch (error: any) {
        yield put(accountApiResponseError(AccountActionTypes.GET_ACCOUNT, error))
    }
}

function* listUserActionSets({ payload: { id }, type }: AccountPayload): SagaIterator {
    try {
        const response = yield call(listUserActionSetsApi, id!);
        const { userActionSetIds } = response.data;
        yield put(accountApiResponseSuccess(AccountActionTypes.LIST_USER_ACTION_SETS, userActionSetIds))
    } catch (error: any) {
        yield put(accountApiResponseError(AccountActionTypes.LIST_USER_ACTION_SETS, error))
    }
}

function* listAgentAccounts({ payload: { id }, type }: AccountPayload): SagaIterator {
    try {
        const response = yield call(listAgentAccountsApi, id!);
        const { accounts } = response.data;
        yield put(accountApiResponseSuccess(AccountActionTypes.LIST_AGENT_ACCOUNTS, accounts))
    } catch (error: any) {
        yield put(accountApiResponseError(AccountActionTypes.LIST_AGENT_ACCOUNTS, error))
    }
}

function* watchListAccounts() {
    yield takeEvery(AccountActionTypes.LIST_ACCOUNTS, listAccounts);
}

function* watchGetAccount() {
    yield takeEvery(AccountActionTypes.GET_ACCOUNT, getAccount);
}

function* watchlistUserActionSets() {
    yield takeEvery(AccountActionTypes.LIST_USER_ACTION_SETS, listUserActionSets);
}

function* watchlistAgentAccounts() {
    yield takeEvery(AccountActionTypes.LIST_AGENT_ACCOUNTS, listAgentAccounts);
}

function* accountSage() {
    yield all([fork(watchListAccounts), fork(watchGetAccount), fork(watchlistUserActionSets), fork(watchlistAgentAccounts)])
}


export default accountSage;