export enum ThirdpartyAppsActionTypes {
    API_RESPONSE_SUCCESS = '@@thirdpartyapp/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@thirdpartyapp/API_RESPONSE_ERROR',
    LIST_THIRDPARTYAPPS = '@@thirdpartyapp/LIST_THIRDPARTYAPPS',
    LIST_ENTERPRISE_THIRDPARTYAPPS = '@@thirdpartyapp/LIST_ENTERPRISE_THIRDPARTYAPPS',
    GET_ACCESSTOKEN = '@@thirdpartyapp/GET_ACCESSTOKEN',
    PUT_THIRDPARTYAPP = '@@thirdpartyapp/PUT_THIRDPARTYAPP',
    DELETE_THIRDPARTYAPP = '@@thirdpartyapp/DELETE_THIRDPARTYAPP',
    RESET = '@@thirdpartyapp/RESET',
}
