import { Page } from 'redux/common';
import { FormSchemaActionTypes } from './constants';

export type FormSchemaActionType = {
  type:
  | FormSchemaActionTypes.API_RESPONSE_SUCCESS
  | FormSchemaActionTypes.API_RESPONSE_ERROR
  | FormSchemaActionTypes.CREATE_FORMSCHEMA
  | FormSchemaActionTypes.LIST_FORMSCHEMAS
  | FormSchemaActionTypes.UPDATE_FORMSCHEMA
  | FormSchemaActionTypes.DELETE_FORMSCHEMA
  | FormSchemaActionTypes.GET_FORMSCHEMA
  | FormSchemaActionTypes.RESET

  payload: {} | string;
}

export interface FormSchemaType {
  id: number
  enterpriseId: number
  schemaId: number
  adsetId: number
  enterprise: any
  formSchemas: string
  formTitle: string
  createdTime: string
  updatedTime: string
}

export interface CreateFormSchemaType {
  name: string
  schema: string
}

export const formSchemaApiResponseSuccess = (actionType: string, data: FormSchemaType | {}, page?: Page): FormSchemaActionType => ({
  type: FormSchemaActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data, page },
});

export const formSchemaApiResponseError = (actionType: string, data: FormSchemaType | {}): FormSchemaActionType => ({
  type: FormSchemaActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, data },
});

export const createFormSchema = (enterpriseId?: number, data?: CreateFormSchemaType,): FormSchemaActionType => ({
  type: FormSchemaActionTypes.CREATE_FORMSCHEMA,
  payload: {
    enterpriseId,
    data
  },
})

export const listFormSchemas = (enterpriseId?: number, page: number = 1, pageSize: number = 20): FormSchemaActionType => ({
  type: FormSchemaActionTypes.LIST_FORMSCHEMAS,
  payload: {
    enterpriseId,
    page,
    pageSize,
  },
})

export const updateFormSchema = (SchemaID: number | string, data: FormSchemaType): FormSchemaActionType => ({
  type: FormSchemaActionTypes.UPDATE_FORMSCHEMA,
  payload: {
    id: SchemaID,
    data,
  },
})

export const deleteFormSchema = (SchemaID: number | string): FormSchemaActionType => ({
  type: FormSchemaActionTypes.DELETE_FORMSCHEMA,
  payload: {
    id: SchemaID,
  },
})

export const getFormSchema = (SchemaID: number | string): FormSchemaActionType => ({
  type: FormSchemaActionTypes.GET_FORMSCHEMA,
  payload: {
    id: SchemaID,
  },
})

export const resetFormSchemaType = (): FormSchemaActionType => ({
  type: FormSchemaActionTypes.RESET,
  payload: {},
})