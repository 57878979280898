import { APICore } from './apiCore';

const api = new APICore();

function getSettings(params: { configKey: string; }): Promise<any> {
    const baseUrl = '/api/v1/config';

    return api.get(`${baseUrl}/${params.configKey}`, {});
}

function setSettings(params: { configKey: string, settings: string }): Promise<any> {
    const baseUrl = '/api/v1/config';

    return api.update(`${baseUrl}/${params.configKey}`, { settings: params.settings });
}


export { getSettings, setSettings };