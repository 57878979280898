import { Page } from 'redux/common';
import { OrdersActionTypes } from "./constants";
import { OrdersType } from "./actions";

const INIT_STATE = {
  orders: [],
  loading: false,
};

type OrdersActionType = {
  type:
  | OrdersActionTypes.API_RESPONSE_SUCCESS
  | OrdersActionTypes.API_RESPONSE_ERROR
  | OrdersActionTypes.LIST_ORDERS
  | OrdersActionTypes.DELETE_ORDER
  | OrdersActionTypes.RESET
  payload: {
    actionType?: string;
    data?: OrdersType[] | OrdersType | {};
    error?: string;
    id?: number;
    page: Page,
  }
}

type State = {
  orders?: OrdersType[];
  loading?: boolean;
  error?: string
};

const findSlice = (data: OrdersType[], orderId: number): number => {
  let idx = data.findIndex((a) => a.id === orderId);
  return idx < 0 ? data.length : idx;
}

const Orders = (state: State = INIT_STATE, action: OrdersActionType) => {
  switch (action.type) {
    case OrdersActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case OrdersActionTypes.LIST_ORDERS: {
          return {
            ...state,
            orders: action.payload.data,
            loading: false,
            page: action.payload.page,
          }
        }
        case OrdersActionTypes.DELETE_ORDER: {
          let { orders = [] } = state,
            idx = findSlice(orders, (action.payload.data as OrdersType).id);
          orders.splice(idx, 1)
          return {
            ...state,
            orders: [...orders],
            loading: false,
          }
        }
        default:
          return { ...state };
      }
    case OrdersActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case OrdersActionTypes.LIST_ORDERS: {
          return {
            ...state,
            error: action.payload.error,
            loading: false
          }
        }
        case OrdersActionTypes.DELETE_ORDER: {
          return {
            ...state,
            error: action.payload.error,
            loading: false
          }
        }
        default:
          return { ...state }
      }
    case OrdersActionTypes.LIST_ORDERS:
      return { ...state, loading: true }
    case OrdersActionTypes.DELETE_ORDER:
      return { ...state, loading: true }
    case OrdersActionTypes.RESET:
      return {
        ...state,
        ...INIT_STATE,
      }
    default:
      return { ...state };
  }
}

export default Orders