import { Page } from "redux/common";
import { OrdersActionTypes } from "./constants";

export type OrdersActionType = {
  type:
  | OrdersActionTypes.API_RESPONSE_SUCCESS
  | OrdersActionTypes.API_RESPONSE_ERROR
  | OrdersActionTypes.LIST_ORDERS
  | OrdersActionTypes.DELETE_ORDER
  | OrdersActionTypes.RESET
  payload: {} | string;
}

export interface OrdersType {
  id: number
  enterpriseId: number
  provider: string
  sourceId: string
  name: string
  username: string
  telephone: string
  clickId: string
  items: any[]
  total: number
  price: number
  quantity: number
  createdTime: string
  updatedTime: string
}

export const ordersApiResponseSuccess = (actionType: string, data: OrdersType | {}, page?: Page): OrdersActionType => ({
  type: OrdersActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data, page },
});

export const ordersApiResponseError = (actionType: string, data: OrdersType | {}): OrdersActionType => ({
  type: OrdersActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, data },
});

export const listOrders = (enterpriseId: number, page: number = 1, pageSize: number = 20): OrdersActionType => ({
  type: OrdersActionTypes.LIST_ORDERS,
  payload: {
    enterpriseId,
    page,
    pageSize,
  },
})

export const deleteOrder = (orderId: number): OrdersActionType => ({
  type: OrdersActionTypes.DELETE_ORDER,
  payload: {
    id: orderId
  },
})

export const resetOrdersType = (): OrdersActionType => ({
  type: OrdersActionTypes.RESET,
  payload: {},
})