import { entPath } from './adset';
import { APICore } from './apiCore';

const api = new APICore();
const baseUrl = "/api/v1"

export function listFormSchemas(enterpriseId?: number, params: {
    'page.page'?: number, // 页起始位置
    'page.pageSize'?: number // 页大小
} = {}) {
    return api.get(entPath("form_schemas", enterpriseId), params);
}

export function createFormSchema(data: any) {
    return api.create(`${baseUrl}/form_schemas`, data)
}

export function createEnterpriseFormSchema(enterpriseId?: number, data?: any) {
    return api.create(entPath('form_schemas', enterpriseId), data)
}

export function deleteFormSchema(SchemaId: number) {
    return api.delete(`${baseUrl}/form_schemas/${SchemaId}`)
}

export function updateFormSchema(SchemaId: number, data?: any) {
    return api.update(`${baseUrl}/form_schemas/${SchemaId}`, data)
}

export function getFormSchema(SchemaId: number) {
    return api.get(`${baseUrl}/form_schemas/${SchemaId}`)
}