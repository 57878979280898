export enum CustomerActionTypes {
  API_RESPONSE_SUCCESS = '@@customer/API_RESPONSE_SUCCESS',
  API_RESPONSE_ERROR = '@@customer/API_RESPONSE_ERROR',

  LIST_CUSTOMERS = '@@customer/LIST_CUSTOMERS',
  CREATE_CUSTOMER = '@@customer/CREATE_CUSTOMER',
  UPDATE_CUSTOMER = '@@customer/UPDATE_CUSTOMER',
  DELETE_CUSTOMER = '@@customer/DELETE_CUSTOMER',
  APPEND_CUSTOMER = '@@customer/APPEND_CUSTOMER',
  REMOVE_CUSTOMER = '@@customer/REMOVE_CUSTOMER',
  GET_CUSTOMER = '@@customer/GET_CUSTOMER',

  RESET = '@@customer/RESET',
}