import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import {
  listUsers as listUsersApi,
  createUser as createUserApi,
  deleteUser as deleteUserApi
} from 'helpers';
import { userApiResponseSuccess, userApiResponseError, UserType } from './actions';
import { UserActionTypes } from './constants';

type UserPayload = {
  payload: {
    id?: string | number,
    data: UserType[] | UserType | {},
    page?: number
    pageSize?: number
    query?: string;
  }
  type: string
}

function* listUsers({ payload: { query, page = 1,
  pageSize }, type }: UserPayload): SagaIterator {
  try {
    const response = yield call(listUsersApi, query, page, pageSize);
    const { users, page: {
      totalCounts
    } } = response.data;
    yield put(userApiResponseSuccess(UserActionTypes.LIST_USERS, users, {
      pageCount: totalCounts,
    }))
  } catch (error: any) {
    yield put(userApiResponseError(UserActionTypes.LIST_USERS, error));
  }
}

function* createUser({ payload: { data }, type }: UserPayload): SagaIterator {
  try {
    const response = yield call(createUserApi, data);
    yield put(userApiResponseSuccess(UserActionTypes.CREATE_USER, response.data))
  } catch (error: any) {
    yield put(userApiResponseError(UserActionTypes.CREATE_USER, error));
  }
}

function* deleteUser({ payload: { id }, type }: UserPayload): SagaIterator {
  try {
    const response = yield call(deleteUserApi, id!);
    yield put(userApiResponseSuccess(UserActionTypes.DELETE_USER, { id }))
  } catch (error: any) {
    yield put(userApiResponseError(UserActionTypes.DELETE_USER, error));
  }
}

function* watchListUsers() {
  yield takeEvery(UserActionTypes.LIST_USERS, listUsers);
}

function* watchCreateUser() {
  yield takeEvery(UserActionTypes.CREATE_USER, createUser);
}

function* watchDeleteUser() {
  yield takeEvery(UserActionTypes.DELETE_USER, deleteUser);
}

function* userSaga() {
  yield all([fork(watchListUsers), fork(watchCreateUser), fork(watchDeleteUser)])
}

export default userSaga;