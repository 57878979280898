import { Page } from 'redux/common';
import { Adset, Params, PushStrategy } from './actions';
import { AdsetActionTypes } from './constants';


const INIT_STATE = {
    adsets: [],
    loading: false,
};

type AdsetParams = {
    id: number;
    params: Params;
}

type AdsetPushStrategy = {
    pushStrategy: PushStrategy
}

type AdsetActionType = {
    type:
    | AdsetActionTypes.API_RESPONSE_SUCCESS
    | AdsetActionTypes.API_RESPONSE_ERROR
    | AdsetActionTypes.LIST_ADSETS
    | AdsetActionTypes.GET_ADSET
    | AdsetActionTypes.PUT_ADSET
    | AdsetActionTypes.START_ADSET
    | AdsetActionTypes.STOP_ADSET
    | AdsetActionTypes.ACK_PUSH
    | AdsetActionTypes.RELENISH_PUSH
    | AdsetActionTypes.CHANGE_PARAMS
    | AdsetActionTypes.CHANGE_PUSHSTRATEGY
    | AdsetActionTypes.RESET;
    payload: {
        actionType?: string;
        data?: Adset[] | Adset | AdsetParams | AdsetPushStrategy | {},
        page: Page,
        id?: number;
        error?: string;
        errorCode?: number;
    };
};

type State = {
    adsets?: Adset[];
    loading?: boolean;
};


const findSlice = (adsets: Adset[], adsetId: number): number => {
    let idx = adsets.findIndex((a) => a.id === adsetId);
    return idx < 0 ? adsets.length : idx;
}

const Adsets = (state: State = INIT_STATE, action: AdsetActionType) => {
    switch (action.type) {
        case AdsetActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case AdsetActionTypes.LIST_ADSETS: {
                    return {
                        ...state,
                        adsets: action.payload.data,
                        page: action.payload.page,
                        loading: false,
                    }
                }
                case AdsetActionTypes.GET_ADSET: {
                    let { adsets = [] } = state,
                        idx = findSlice(adsets, (action.payload.data as Adset).id);
                    return {
                        ...state,
                        adsets: [...adsets.slice(0, idx), action.payload.data, ...adsets.slice(idx + 1)],
                        loading: false,
                    }
                }
                case AdsetActionTypes.PUT_ADSET: {
                    let { adsets = [] } = state,
                        idx = findSlice(adsets, (action.payload.data as Adset).id);
                    return {
                        ...state,
                        adsets: [...adsets.slice(0, idx), action.payload.data, ...adsets.slice(idx + 1)],
                        loading: false,
                    }
                }
                case AdsetActionTypes.CHANGE_PARAMS: {
                    let { adsets = [] } = state,
                        idx = findSlice(adsets, (action.payload.data as Adset).id),
                        params = (action.payload?.data as AdsetParams).params,
                        adset = adsets[idx];
                    return {
                        ...state,
                        adsets: [...adsets.slice(0, idx), { ...adset, params }, ...adsets.slice(idx + 1)]
                    }
                }

                case AdsetActionTypes.CHANGE_PUSHSTRATEGY: {
                    let { adsets = [] } = state,
                        idx = findSlice(adsets, (action.payload.data as Adset).id),
                        pushStrategy = (action.payload?.data as AdsetPushStrategy).pushStrategy,
                        adset = adsets[idx];
                    return {
                        ...state,
                        adsets: [...adsets.slice(0, idx), { ...adset, pushStrategy }, ...adsets.slice(idx + 1)]
                    }
                }

                case AdsetActionTypes.START_ADSET: {
                    let { adsets = [] } = state,
                        idx = findSlice(adsets, action.payload.id!);
                    return {
                        ...state,
                        adsets: [...adsets.slice(0, idx), action.payload.data, ...adsets.slice(idx + 1)],
                        loading: false,
                    }
                }
                case AdsetActionTypes.STOP_ADSET: {
                    let { adsets = [] } = state,
                        idx = findSlice(adsets, action.payload.id!);
                    return {
                        ...state,
                        adsets: [...adsets.slice(0, idx), action.payload.data, ...adsets.slice(idx + 1)],
                        loading: false,
                    }
                }
                case AdsetActionTypes.ACK_PUSH: {
                    return {
                        ...state,
                        loading: false,
                    }
                }
                case AdsetActionTypes.RELENISH_PUSH: {
                    return {
                        ...state,
                        // adsets: action.payload.data,
                        loading: false,
                    }
                }
                default:
                    return { ...state };
            }
        case AdsetActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case AdsetActionTypes.LIST_ADSETS: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    }
                }
                case AdsetActionTypes.GET_ADSET: {
                    return {
                        ...state,
                        error: action.payload.data,
                        errorCode: action.payload.errorCode,
                        loading: false,
                    }
                }
                case AdsetActionTypes.PUT_ADSET: {
                    return {
                        ...state,
                        error: action.payload.data,
                        errorCode: action.payload.errorCode,
                        loading: false,
                    }
                }
                case AdsetActionTypes.CHANGE_PARAMS: {
                    return {
                        ...state,
                        error: action.payload.data,
                        errorCode: action.payload.errorCode,
                    }
                }
                case AdsetActionTypes.CHANGE_PUSHSTRATEGY: {
                    return {
                        ...state,
                        error: action.payload.data,
                        errorCode: action.payload.errorCode,
                    }
                }
                case AdsetActionTypes.START_ADSET: {
                    return {
                        ...state,
                        error: action.payload.data,
                        errorCode: action.payload.errorCode,
                        loading: false,
                    }
                }
                case AdsetActionTypes.STOP_ADSET: {
                    return {
                        ...state,
                        error: action.payload.data,
                        errorCode: action.payload.errorCode,
                        loading: false,
                    }
                }
                case AdsetActionTypes.ACK_PUSH: {
                    return {
                        ...state,
                        error: action.payload.data,
                        errorCode: action.payload.errorCode,
                        loading: false,
                    }
                }
                case AdsetActionTypes.RELENISH_PUSH: {
                    return {
                        ...state,
                        error: action.payload.data,
                        errorCode: action.payload.errorCode,
                        loading: false,
                    }
                }
                default:
                    return { ...state };
            }
        case AdsetActionTypes.LIST_ADSETS:
            return { ...state, loading: true }
        case AdsetActionTypes.GET_ADSET:
            return { ...state, loading: true }
        case AdsetActionTypes.PUT_ADSET:
            return { ...state, loading: true }
        case AdsetActionTypes.START_ADSET:
            return { ...state, loading: true }
        case AdsetActionTypes.STOP_ADSET:
            return { ...state, loading: true }
        case AdsetActionTypes.ACK_PUSH:
            return { ...state, loading: true }
        case AdsetActionTypes.RELENISH_PUSH:
            return { ...state, loading: true }
        case AdsetActionTypes.CHANGE_PARAMS:
            return { ...state }
        case AdsetActionTypes.CHANGE_PUSHSTRATEGY:
            return { ...state }
        case AdsetActionTypes.RESET:
            return {
                ...state,
                ...INIT_STATE,
            }
        default:
            return { ...state };
    }
}

export default Adsets;
