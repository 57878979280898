import { Page } from 'redux/common';
import { ThirdpartyAppsActionTypes } from './constants';

export type ThirdpartyAppActionType = {
    type:
    | ThirdpartyAppsActionTypes.API_RESPONSE_SUCCESS
    | ThirdpartyAppsActionTypes.API_RESPONSE_ERROR
    | ThirdpartyAppsActionTypes.LIST_THIRDPARTYAPPS
    | ThirdpartyAppsActionTypes.LIST_ENTERPRISE_THIRDPARTYAPPS
    | ThirdpartyAppsActionTypes.GET_ACCESSTOKEN
    | ThirdpartyAppsActionTypes.PUT_THIRDPARTYAPP
    | ThirdpartyAppsActionTypes.DELETE_THIRDPARTYAPP
    | ThirdpartyAppsActionTypes.RESET
    payload: {} | string;
};


export interface ThirdpartyApp {
    id: number
    provider: string
    appType: string
    enterpriseId: number
    enterprise: any
    creatorId: number
    creator: any
    manageAppId: string
    appId: string
    name: string
    componentAccessToken: string
    componentAccessTokenExpiresAt: Date
    authorizerAccessToken: string
    authorizerAccessTokenExpiresAt: Date
    authorizerRefreshToken: string
    authorizerRefreshTokenExpiresAt: Date
    blockedAt: Date
    blockedReason: string
    tags: any[]
    createdTime: string
    updatedTime: string
}



export const thirdpartyAppApiResponseSuccess = (actionType: string, data: ThirdpartyApp | {}, page?: Page): ThirdpartyAppActionType => ({
    type: ThirdpartyAppsActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data, page },
});

export const thirdpartyAppApiResponseError = (actionType: string, data: ThirdpartyApp | {}): ThirdpartyAppActionType => ({
    type: ThirdpartyAppsActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, data },
});

export const listThirdpartyApps = (page: number = 1, pageSize: number = 20): ThirdpartyAppActionType => ({
    type: ThirdpartyAppsActionTypes.LIST_THIRDPARTYAPPS,
    payload: {
        page,
        pageSize
    },
})

export const listEnterpriseThirdpartyApps = (enterpriseId: number, page: number = 1, pageSize: number = 20): ThirdpartyAppActionType => ({
    type: ThirdpartyAppsActionTypes.LIST_ENTERPRISE_THIRDPARTYAPPS,
    payload: {
        enterpriseId,
        page,
        pageSize
    },
})

export const getAccessToken = (thirdPartyAppId: number) => ({
    type: ThirdpartyAppsActionTypes.GET_ACCESSTOKEN,
    payload: {
        id: thirdPartyAppId,
    }
})

export const putThirdpartyApp = (app: ThirdpartyApp) => ({
    type: ThirdpartyAppsActionTypes.PUT_THIRDPARTYAPP,
    payload: {
        data: app,
    }
})

export const deleteThirdpartyapps = (thirdPartyAppId: number) => ({
    type: ThirdpartyAppsActionTypes.DELETE_THIRDPARTYAPP,
    payload: {
        id: thirdPartyAppId,
    }
})

export const resetThirdpartyApp = (): ThirdpartyAppActionType => ({
    type: ThirdpartyAppsActionTypes.RESET,
    payload: {},
})